exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-countdown-tsx": () => import("./../../../src/pages/countdown.tsx" /* webpackChunkName: "component---src-pages-countdown-tsx" */),
  "component---src-templates-agenda-tsx": () => import("./../../../src/templates/agenda.tsx" /* webpackChunkName: "component---src-templates-agenda-tsx" */),
  "component---src-templates-exposition-page-tsx": () => import("./../../../src/templates/expositionPage.tsx" /* webpackChunkName: "component---src-templates-exposition-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/newsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-nieuws-tsx": () => import("./../../../src/templates/nieuws.tsx" /* webpackChunkName: "component---src-templates-nieuws-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-videos-tsx": () => import("./../../../src/templates/videos.tsx" /* webpackChunkName: "component---src-templates-videos-tsx" */)
}

